<template>
	<div id="content">
		<Header v-bind:infos="infos" />
		<main>
			<div class="container">
				<form class="row" @submit.prevent="sendEmail">
					<div class="champsFormulaire col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<label class="col-sm-12 col-md-12 col-lg-12 col-xl-12">NOM</label>
						<input
							class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
							type="text"
							v-model="name"
							name="name"
							placeholder="Entrez votre nom"
						/>

						<hr class="ligneSeparation" />

						<label class="col-sm-12 col-md-12 col-lg-12 col-xl-12">EMAIL</label>
						<input
							class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
							type="email"
							v-model="email"
							name="email"
							placeholder="Entrez votre email"
						/>

						<hr class="ligneSeparation" />

						<label class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
							>MESSAGE</label
						>
						<textarea
							class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
							name="message"
							v-model="message"
							cols="30"
							rows="5"
							placeholder="Renseignez ici toutes les informations que vous souhaitez me transmettre"
						>
						</textarea>

						<hr class="ligneSeparation" />
					</div>

					<div class="boutonsContact col-sm-12 col-md-12 col-lg-12 col-xl-12">
						<input class="boutonEnvoyer" type="submit" value="ENVOYER" />
						<a
							href="https://antoninwinterstein.com/CV_Antonin_Winterstein.pdf"
							download="CV - Antonin Winterstein"
							class="boutonTelechargerCV"
							>CV</a
						>
					</div>
					<p
						class="etatFormulaire text-center col-sm-12 col-md-12 col-lg-12 col-xl-12"
					>
						<b>{{ etatFormulaire }}</b>
					</p>
				</form>
			</div>
		</main>
	</div>
</template>

<script>
import emailjs from "emailjs-com";
import Header from "@/components/Header.vue";

export default {
	metaInfo: function() {
		return {
			title: "Antonin Winterstein | Contact",
			meta: [
				{
					name: "description",
					content: "Prenez ici contact avec Antonin Winterstein !",
				},
			],
		};
	},
	components: {
		Header,
	},
	data() {
		return {
			etatFormulaire: "",
			show: false,
			name: "",
			email: "",
			message: "",
			infos: {
				titrePage: "CONTACT",
				urlImage: require("../assets/contact/fond_contact.jpg"),
			},
		};
	},
	methods: {
		sendEmail(e) {
			if (this.name && this.email && this.message) {
				try {
					emailjs.sendForm(
						"contact_portfolio",
						"contact_portfolio",
						e.target,
						"user_HxZIQtsnscw66wNFuVQ9M",
						{
							name: this.name,
							email: this.email,
							message: this.message,
						}
					);
					this.etatFormulaire = "Votre message a bien été envoyé !";
				} catch (error) {
					console.log({ error });
				}
				// Reset form field
				this.name = "";
				this.email = "";
				this.message = "";
			} else if (this.name && this.email) {
				this.etatFormulaire =
					'Veuillez remplir le champ "MESSAGE" afin de valider le formulaire.';
			} else if (this.name && this.message) {
				this.etatFormulaire =
					'Veuillez remplir le champ "EMAIL" afin de valider le formulaire.';
			} else if (this.email && this.message) {
				this.etatFormulaire =
					'Veuillez remplir le champ "NOM" afin de valider le formulaire.';
			} else if (this.name) {
				this.etatFormulaire =
					'Veuillez remplir les champs "EMAIL" et "MESSAGE" afin de valider le formulaire.';
			} else if (this.email) {
				this.etatFormulaire =
					'Veuillez remplir les champs "NOM" et "MESSAGE" afin de valider le formulaire.';
			} else if (this.message) {
				this.etatFormulaire =
					'Veuillez remplir les champs "NOM" et "EMAIL" afin de valider le formulaire.';
			} else {
				this.etatFormulaire =
					"Aucun champ n'a été rempli, les trois sont obligatoires afin de valider le formulaire.";
			}
		},
	},
	mounted() {
		window.scrollTo(0, 0);
	},
};
</script>

<style lang="scss">
/*Contact*/

main {
	margin-top: 3vh;
	div form {
		.champsFormulaire {
			label {
				font-family: Heebo;
				font-weight: bold;
				color: #fbfbfb;
				font-size: calc(0.5vw + 0.5vh + 2vmin);
				padding: 0;
			}

			input:not(:last-child),
			textarea {
				font-family: Heebo;
				color: #707070;
				font-size: calc(0vw + 0vh + 2vmin);

				cursor: text;
				background-color: transparent;
				border: 0;
				padding: 0;
			}

			.ligneSeparation {
				margin: 2vh 0 5vh 0;
				border-top: 1px solid #707070;
			}

			.ligneSeparation:last-child {
				margin-bottom: -1vh;
			}
		}

		div.boutonsContact {
			display: flex;
			justify-content: space-between;
		}

		p.etatFormulaire {
			color: #e62929;
		}
	}
}
</style>
